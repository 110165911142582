$(document).ready(function() {
    //GOTOP
    $('#GOTOP').click(function() {
        jQuery('html, body').animate({
            scrollTop: 0
        }, 500);
    });
    $(window).scroll(function() {
        if ($(this).scrollTop() > 300) {
            $('#GOTOP').fadeIn('fast');
        } else {
            $('#GOTOP').stop().fadeOut('fast');
        }
    });
    $(window).load(function() {
        $('#preloader').fadeOut(800, function() {
            $(this).fadeOut();
        });
    });

});

$(document).ready(function() {
    $("#menu").mmenu({
        "extensions": [
            "border-full",
            "pageshadow",
            "theme-dark"
        ],
        "offCanvas": {
            "position": "right",
        },
        "dragOpen": {
            "open": true,
        },
        "zposition": "next",
    });
});
